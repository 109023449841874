<template >
<div>
  <div v-if="printTemplateURL != ''" class="nx-printTemplate">
    <div class="row">
      <div class="col-12 nx-printTemplate-box">
        <div>
          <template >
            <div>

               <a :href="printTemplateURL" target="_blank"> <i class="fas fa-file-pdf"></i><div class="printTemplate-text">Gestaltungshinweise</div></a>
            </div>
          </template>

        </div>
      </div>

    </div>
     <hr>
  </div>
</div>
</template>

<script>
export default {
  name: "ItemPropertieprintTemplate",
  props: {
    item: null,
  },
 data() {
    return {
      showLink:false
    };
  },
    computed: {
    printTemplateURL() {
     let url = "";
     let probs = null;
     let variation = this.$store.getters.currentItemVariation;
     if(variation.hasOwnProperty('variationProperties')){
        probs = variation.variationProperties[0].properties;

        probs.forEach(element => {
        if (element.id == 6)
          {
          url = element.values.value;

         this.showLink = true;
          }

        });

    }


      return url;

  }
  },

};
</script>

<style lang="scss" scoped>


</style>
