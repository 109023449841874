<template>
  <div v-if="packagesLoaded">
    <hr />
    <div class="row">
      <strong class="col-6 col-sm-5">Sendungsverfolgung</strong>
      <span class="col-6 col-sm-7">
        <div v-if="packageNumbers[0]">
          <div v-for="(data, provider) of packagesByProvider">
            <div v-if="data.packages[0]">
              <p>Versendet mit {{ provider }}</p>
              <a
                v-for="(packageNumber, index) of data.packages"
                target="_blank"
                :href="data.link + packageNumber"
                class="btn btn-primary mb-1"
              >
                <span class="button-text">Sendung {{ index + 1 }} verfolgen</span>
              </a>
            </div>
          </div>
        </div>

        <div v-if="orderStatus > 7.7 && !packageNumbers[0]">
          <strong>Noch kein Online-Tracking verfügbar.</strong>
          <br />
          <a href="https://www.net-xpress.de/support/versand/">Statusanfrage an Support</a>
        </div>

        <div v-if="orderStatus < 7.7 && !packageNumbers[0]">
          <strong>Noch kein Online-Tracking verfügbar</strong>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ups-shipment-tracking",

  props: {
    orderId: {
      type: Number,
      required: true,
    },
    orderStatus: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      packageNumbers: [],
      packagesLoaded: false,
    };
  },

  created() {
    this.fetchPackages();
  },

  computed: {
    packagesByProvider() {
      let packagesByProvider = {
        DHL: {
          link: "https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=",
          packages: [],
        },
        UPS: {
          link: "https://www.ups.com/track?loc=de_de&tracknum=",
          packages: [],
        },
        Hellmann: {
          link: "https://public.tracking.hellmann.net/search/",
          packages: [],
        },
      };

      for (const packageNumber of this.packageNumbers) {
        if (packageNumber.indexOf("1Z") === 0) {
          packagesByProvider.UPS.packages.push(packageNumber);
        } else if (packageNumber.indexOf("007") === 0) {
          packagesByProvider.Hellmann.packages.push(packageNumber);
        } else {
          packagesByProvider.DHL.packages.push(packageNumber);
        }
      }

      return packagesByProvider;
    },
  },

  methods: {
    async fetchPackages() {
      // The route only returns the array of packages, if the order has the Tag "UPS"
      const response = await axios.get("/rest/jd/getPackageNumbersForOrder?orderId=" + this.orderId);
      this.packagesLoaded = true;

      let packages = response.data;
      if (!packages[0]) return;

      for (const plentyPackage of packages) {
        if (plentyPackage.packageNumber) {
          this.packageNumbers.push(plentyPackage.packageNumber);
        }
      }

    },
  },
};
</script>

<style lang="scss" scoped></style>
