<template>
  <div>
    <form class="w-100" autocomplete="on" method="post" @submit.prevent="validateRegistration()" ref="registrationForm">
        <div class="row">
            <div class="col-12">
                <address-input-group-netxpress
                    template="#vue-address-input"
                    v-if="!isSimpleRegistration"
                    address-type="1"
                    :value="billingAddress"
                    @input="setAddressDataField($event)"
                    :optional-address-fields="shownFields"
                    :required-address-fields="requiredFields"
                    :default-salutation="defaultSalutation">
                    <template #custom-address-fields>
                        <slot name="custom-address-fields"></slot>
                    </template>
                </address-input-group-netxpress>
            </div>
        </div>
         <div class="row">
           <div class="col-sm-12">
                <div class="input-unit" data-validate="mail">
                    <input data-testing="mail-register" type="email" name="email" autocomplete="email" :id="'email'+_uid" v-model="username" data-autofocus>
                    <label :for="'email'+_uid">{{ $translate("Ceres::Template.regEmail") }}*</label>
                </div>
            </div>
           <div class="col-12">
                <div class="border-top mt-4 mb-4"></div>
                <span class="hr-text">Ihr Zugang</span>
            </div>
        
          
             <div class="col-md-6">
               <div class="row">
                   <div class="col-1 options">
                     <label>
                        <input type="radio" name="optradio" value="own" v-on:change="setUnsetPass"v-model="picked"/> 
                        <div />  
                    </label>
                    </div>
                     <div class="col-11 text-password">
                        <p>- Sicherer Zugang mit SSL-Verschlüsselung</p>
                        <p>- Zugriff auf Ihre Aufträge mit Status, Dokumenten etc. </p>
                        <p>- Ihr B2B-Konto mit möglichen Rabatten bei Folgeaufträgen</p>
                        <p>- Keine Anmeldung zu Newsletter oder Werbemaßnahmen </p>
                    </div>
                </div>
             </div>
            
            <div class="col-md-6 ">
                <div class="row">
                      <div class="col-sm-12" v-if="!guestMode">
                            <div class="input-unit" :class="{'no-bottom media-xs-d': modalElement}" data-validate="password" ref="passwordInput">
                                <popper v-cloak trigger="focus" placement="bottom" ref="passwordHint">
                                    <template #handle>
                                        <input data-testing="password-register" type="password" name="password" autocomplete="new-password" :id="'new-password-' + _uid" v-model="password">
                                    </template>

                                    <template #title>
                                        <div>
                                            {{ $translate("Ceres::Template.regPasswordHintTitle") }}
                                        </div>
                                    </template>

                                    <template #content>
                                        <ul class="pl-3">
                                            <li>{{ $translate("Ceres::Template.regPasswordHintLength") }}</li>
                                            <li>{{ $translate("Ceres::Template.regPasswordHintDigit") }}</li>
                                            <li>{{ $translate("Ceres::Template.regPasswordHintChar") }}</li>
                                        </ul>
                                    </template>
                                </popper>

                                <label :for="'new-password-' + _uid">{{ $translate("Ceres::Template.regPassword") }}*</label>
                            </div>
                      </div>
                        <div class="col-sm-12 input-unit-group" v-if="!guestMode">
                            <div class="input-unit" :class="{'no-bottom': modalElement}" data-validate="ref">
                                <input type="password" name="password-repeat" autocomplete="new-password" :id="'new-password-repeat-' + _uid" v-model="passwordRepeat" :data-validate-ref="'#new-password-' + _uid" data-testing="repeat-password-register">
                                <label :for="'new-password-repeat-' + _uid" class='repeat-pass-label'>{{ $translate("Ceres::Template.regRepeatPassword") }}*</label>
                            </div>
                        </div>
                </div>
            </div>
            <div class="col-12">
                <div class="border-top mt-4 mb-4"></div>
            </div>
             <div class="col-12 options">

            
                    <label>
                        <input type="radio" name="optradio" value="generated" v-on:change="setUnsetPass"v-model="picked"/> 
                        <div />   
                    </label>
                     <span class='label'>- Ich möchte kein Passwort wählen (das kann ich später jederzeit kostenfrei nachholen)</span>
             
             
             </div>
            <div class="col-12" v-if="enableConfirmingPrivacyPolicy">
                <accept-privacy-policy-check
                        class="mt-3 mb-0"
                        v-model="privacyPolicyAccepted"
                        @input="privacyPolicyValueChanged($event)"
                        :show-error="privacyPolicyShowError">
                </accept-privacy-policy-check>
            </div>
        </div>
        <div class="border-top mt-2 text-right">
            <slot name="extend-overlay-buttons"></slot>

            <button :disabled="isDisabled" class="btn btn-appearance btn-primary btn-medium mt-3" :class="buttonSizeClass" data-testing="register-submit">
                {{ $translate("Ceres::Template.regRegister") }}
                <icon icon="user-plus" class="default-float" :loading="isDisabled"></icon>
            </button>
        </div>

        <recaptcha v-if="!!googleRecaptchaApiKey && (modalShown || !isSimpleRegistration)"></recaptcha>
    </form>
  <div id='componentlogin'class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Schön, dass Sie wieder da sind!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Sie sind bereits Kunde bei uns und können gleich fortfahren.
               Haben Sie Ihr Passwort vergessen oder beim letzten mal auf ein Passwort verzichtet?</p>
      </div>
      <div class="modal-footer">
        <button type="button" @click="showResetPwdView" class="btn btn-primary">mein Passwort per eMail erhalten</button>
        <button type="button" @click="showLoginView" class="btn btn-primary">mit bekanntem Passwort anmelden</button>

      </div>
    </div>
  </div>
</div>
  </div>
   
</template>

<script>
import ValidationService from "Ceres/app/services/ValidationService";
import { navigateTo } from "Ceres/app/services/UrlService";
import { executeReCaptcha } from "Ceres/app/helper/executeReCaptcha";
import { isNullOrUndefined, isDefined } from "Ceres/app/helper/utils";
import { ButtonSizePropertyMixin } from "Ceres/app/mixins/buttonSizeProperty.mixin";
import AddressInputGroup from "./AddressInputGroupNetxpress.vue";
import ApiService from "Ceres/app/services/ApiService";
import NotificationService from "Ceres/app/services/NotificationService";
import ModalService from "Ceres/app/services/ModalService";
import AcceptPrivacyPolicyCheck from "Ceres/app/components/customer/AcceptPrivacyPolicyCheck.vue";

export default {

    components:
    {
        AddressInputGroup,
        AcceptPrivacyPolicyCheck
    },

    mixins: [ButtonSizePropertyMixin],

    props: {
        modalElement: String,
        guestMode: { type: Boolean, default: false },
        isSimpleRegistration: { type: Boolean, default: false },
        template: String,
        backlink: String,
        shownFields: Object,
        requiredFields: Object,
        defaultSalutation:  {
            type: String,
            default: App.config.addresses.defaultSalutation
        }
    },

    data()
    {
        return {
            setemail:true,
            picked : 'own',
            password  : "",
            passwordRepeat: "",
            username      : "",
            billingAddress: {
                countryId: null,
                stateId: null,
                gender: this.defaultSalutation
            },
            isDisabled: false,
            privacyPolicyAccepted : false,
            privacyPolicyShowError: false,
            enableConfirmingPrivacyPolicy: App.config.global.registrationRequirePrivacyPolicyConfirmation,
            googleRecaptchaApiKey: App.config.global.googleRecaptchaApiKey,
            modalShown: false,
            honeypot: ""
        };
    },

    mounted()
    {
        this.$store.dispatch("loadComponent", "forgot-password-modal");
           


        this.$nextTick(() =>
        {
            if (this.modalElement)
            {
                this.initModalEventListeners();
            }
            
            $(document).ready(setTimeout(function(){ 
                        $("#resetPwd").on('hidden.bs.modal', function () {
                            const custombtn_zu = $("#nx-abbrechen")
                            if(custombtn_zu.length > 0){
                                $("#nx-abbrechen").remove()
                                $('#resetPwd .modal-footer button:first').show()
                            }
                        })
                        $("#resetPwd").on('show.bs.modal', function () {
                          $('#resetPwd .modal-footer button:last-child').text('Passwort anfordern').prepend('<i aria-hidden="true" class="fa fa-paper-plane-o"></i>')

                         
                        })
            },300))
             
             
        });

            
    },


    methods: {
        /**
         * Validate the registration form
         */
  
         showResetPwdView(){
             const custombtn_zu = $("#nx-abbrechen")
             if(custombtn_zu.length == 0){
                       $('#resetPwd .modal-footer button:first').hide()
                       $('#resetPwd .modal-footer div').prepend(`<a id='nx-abbrechen'  onClick="(function(){$('#resetPwd').modal('toggle');})();return false;" 
                                                                             class="btn btn-default btn-appearance btn-medium mr-2">
                                                                             Abbrechen</a>`)
                }
             
             
             $('#componentlogin').modal('toggle');
             //this.$store.dispatch("loadComponent", "forgot-password-modal");
             setTimeout(()=>{    
              $('#resetPwd input[name="email"]').val(this.username)[0].dispatchEvent(new Event('input'));
              }, 100);
             $('#resetPwd').modal('show');
         },
         showLoginView(){
            
              $('#componentlogin').modal('toggle');
              this.$store.dispatch("loadComponent", "login-modal");
              setTimeout(()=>{  
                 $('#login input[name="email"]').val(this.username)[0].dispatchEvent(new Event('input'));;
              }, 100);
              
              $('#login').modal('show');
         },
         setUnsetPass(){
             this.password = this.passwordRepeat = (this.picked == 'own' ) ? "" : this.password_generator(8)
            if(this.password == ""){
                $('#new-password-'+ this._uid).prop( "disabled", false ).css("color","black")
                $('#new-password-repeat-'+ this._uid).prop( "disabled", false ).css("color","black")
            }
         },
        validateRegistration()
        {
            executeReCaptcha(this.$refs.registrationForm)
                .then((recaptchaToken) =>
                {
                    ValidationService.validate(this.$refs.registrationForm)
                        .done(() =>
                        {
                             $('.notification-wrapper').text('')
                            if (!this.enableConfirmingPrivacyPolicy || this.privacyPolicyAccepted)
                            {
                                this.sendRegistration(recaptchaToken);
                            }
                            else
                            {
                                this.privacyPolicyShowError = true;
                                NotificationService.error(
                                    this.$translate("Ceres::Template.contactAcceptFormPrivacyPolicy", { hyphen: "&shy;" })
                                );
                                
                                $('.notification-wrapper').append( `<div role="alert" class="alert alert-dismissible fade in show alert-danger">
                                 <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                  `+ this.$translate("Ceres::Template.contactAcceptFormPrivacyPolicy", { hyphen: "&shy;" }) +
                                  `</div>`)
                               
                                this.resetRecaptcha();
                            }
                        })
                        .fail(invalidFields =>
                        {
                            this.resetRecaptcha();

                            if (!isNullOrUndefined(this.$refs.passwordHint) && invalidFields.indexOf(this.$refs.passwordInput) >= 0)
                            {
                                this.$refs.passwordHint.showPopper();
                            }

                            const invalidFieldNames = this.getInvalidFieldNames(invalidFields);

                            if (invalidFieldNames.length > 0)
                            {
                                
                                NotificationService.error(
                                    this.$translate("Ceres::Template.checkoutCheckAddressFormFields", { fields: invalidFieldNames.join(", ") })
                                );
                                    $('.notification-wrapper').append( `<div role="alert" class="alert alert-dismissible fade in show alert-danger">
                                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> 
                                  `+ this.$translate("Ceres::Template.checkoutCheckAddressFormFields", { fields: invalidFieldNames.join(", ") }) +
                                  `</div>`)
                                
                            }

                            ValidationService.markInvalidFields(invalidFields, "error");

                            if (this.enableConfirmingPrivacyPolicy && !this.privacyPolicyAccepted)
                            {
                                this.privacyPolicyShowError = true;

                                NotificationService.error(
                                    this.$translate("Ceres::Template.contactAcceptFormPrivacyPolicy", { hyphen: "&shy;" })
                                );
                                  $('.notification-wrapper').append( `<div role="alert" class="alert alert-dismissible fade in show alert-danger">
                                  <button type="button" aria-label="Close" class="close"><span aria-hidden="true">×</span></button> 
                                  `+ this.$translate("Ceres::Template.contactAcceptFormPrivacyPolicy", { hyphen: "&shy;" }) +
                                  `</div>`)
                            }
                        });
                });
        },

        getInvalidFieldNames(invalidFields = [])
        {
            const fieldNames = [];

            for (const field of invalidFields)
            {
                let fieldName = field.lastElementChild.innerHTML.trim();

                fieldName = fieldName.slice(-1) === "*" ? fieldName.slice(0, fieldName.length - 1) : fieldName;
                fieldNames.push(fieldName);
            }

            return fieldNames;
        },

        /**
         * Send the registration
         */
        sendRegistration(recaptchaToken)
        {
            const userObject = this.getUserObject();

            userObject.recaptcha = recaptchaToken;

            this.isDisabled = true;

            ApiService.post("/rest/io/customer", userObject)
                .done(response =>
                {
                    ApiService.setToken(response);

                    if (!response.code)
                    {
                        document.dispatchEvent(new CustomEvent("onSignUpSuccess", { detail: userObject }));

                        NotificationService.success(
                            this.$translate("Ceres::Template.regSuccessful")
                        ).closeAfter(3000);

                        if (document.getElementById(this.modalElement) !== null)
                        {
                            ModalService.findModal(document.getElementById(this.modalElement)).hide();
                        }

                        if (this.backlink !== null && this.backlink)
                        {
                            navigateTo(decodeURIComponent(this.backlink));
                        }
                        else
                        {
                            location.reload();
                        }
                    }
                    else
                    {
                        NotificationService.error(
                            this.$translate("Ceres::Template.regError")
                        ).closeAfter(10000);
                        //alert(this.$translate("Ceres::Template.regError"))
                        $('#componentlogin').modal('toggle');
                        this.resetRecaptcha();
                    }

                    this.isDisabled = false;
                })
                .fail((error) =>
                {
                    NotificationService.error(error.error).closeAfter(10000);

                    this.resetRecaptcha();

                    this.isDisabled = false;
                });
        },

        /** 
         * Resets recaptcha v2 to make it capable of executing again.
        */
        resetRecaptcha()
        {
            if(App.config.global.googleRecaptchaVersion === 2 && window.grecaptcha)
            {
                const recaptchaId = this.$refs.registrationForm.querySelector("[data-recaptcha]");

                window.grecaptcha.reset(recaptchaId);
            }
        },

        setAddressDataField({ field, value })
        {
            this.billingAddress[field] = value;
            this.billingAddress = Object.assign({}, this.billingAddress);
        },

        /**
         * Handle the user object which is send to the server
         * @returns {{contact: {referrerId: number, typeId: number, options: {typeId: {typeId: number, subTypeId: number, value: *, priority: number}}}, honeypot: string}|{contact: {referrerId: number, typeId: number, password: *, options: {typeId: {typeId: number, subTypeId: number, value: *, priority: number}}}, honeypot: string}}
         */
        getUserObject()
        {
            const userObject =
                {
                    contact: {
                        referrerId: 1,
                        typeId    : 1,
                        options   : {
                            typeId: {
                                typeId   : 2,
                                subTypeId: 4,
                                value    : this.username,
                                priority : 0
                            }
                        }
                    },
                    honeypot: this.honeypot
                };

            if (!this.guestMode)
            {
                userObject.contact.password = this.password;
            }

            if (!this.isSimpleRegistration)
            {
                userObject.billingAddress = this.billingAddress;
            }

            return userObject;
        },

        privacyPolicyValueChanged(value)
        {
            this.privacyPolicyAccepted = value;

            if (value)
            {
                this.privacyPolicyShowError = false;
            }
        },

        initModalEventListeners()
        {
            const modal = ModalService.findModal(document.getElementById(this.modalElement));
            
            if (isDefined(modal))
            {
                modal.on("show.bs.modal",
                    () =>
                    {
                        this.modalShown = true;
                    });

                modal.on("hide.bs.modal",
                    () =>
                    {
                        this.modalShown = false;
                    });
            }
        },
        password_generator( len ) {
                $('#new-password-'+ this._uid).prop( "disabled", true ).css("color","white")
                $('#new-password-repeat-'+ this._uid).prop( "disabled", true ).css("color","white")
                var length = (len)?(len):(10);
                var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
                var numeric = '0123456789';
                var punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
                var password = "";
                var character = "";
                var entity1 = "";
                var entity2 = "";
                var entity3 = "";
                var hold = "";
                
                var crunch = true;
                while( password.length<length ) {
                    entity1 = Math.ceil(string.length * Math.random()*Math.random());
                    entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
                    entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
                    hold = string.charAt( entity1 );
                    hold = (password.length%2==0)?(hold.toUpperCase()):(hold);
                    character += hold;
                    character += numeric.charAt( entity2 );
                    character += punctuation.charAt( entity3 );
                    password = character;
                }
                password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
                
                
                return password.substr(0,len);
        }
    }
}
</script>

<style>
#page-body .registration.container-max #nx-registration-container form div p {
font-size: 0.9em;
color: #555;
padding: 0px;
margin: 0px;
}

.text-password {
    padding-left: 18px;
    padding-top: 4px;
}
#page-body .registration.container-max #nx-registration-container .col-12 .form-check {
    float: right;
    margin-top: 10px !important;
}
div.options > label > input {
    visibility: hidden;
}

div.options > label {
    display: block;
    margin: 0 0 0 -10px;
    padding: 0 0 20px 0;  
    height: 20px;
    width: 20px;
    
}

div.options > label > div {
    display: inline-block;
    padding: 0px;
    height:22px;
    width:22px;
    background: none;
    position:absolute;
}
div.options > label > input +div{
    background: url('https://cdn02.plentymarkets.com/e3qsx9u6covd/frontend/Ceres/Versandprofilauswahl/Checkbox_unchecked.png');
    background-repeat: no-repeat;
    background-position:center center;
    background-size:50px 30px;  
}
div.options > label > input:checked +div {  
    background: url(https://cdn02.plentymarkets.com/e3qsx9u6covd/frontend/Ceres/Versandprofilauswahl/Checkbox_checked.png);
    background-repeat: no-repeat;
    background-position:center center;
    background-size:50px 30px;
}
span.label {
     position: absolute;
    top: 4px;
    left: 50px
}
.col-12.options {
    margin-bottom: 25px;
}

.input-unit input[type].disabled + label.repeat-pass-label,
 .input-unit input[type]:disabled + label.repeat-pass-label {
    color: #555 !important;
}
a#nx-abbrechen:hover {
    border-color: var(--nx-brand-primary) !important;
    background: var(--nx-brand-primary) !important;
    color: #fff !important;
}
a#nx-abbrechen {
    background: white;
    color: var(--nx-brand-primary) !important;
    border: 1px solid var(--nx-brand-primary) !important;
}

#resetPwd .modal-footer button:last-child {
    min-width: 15rem;
}
</style>
