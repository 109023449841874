<template>
  <div class="col-sm-4" :class="paddingClasses" :style="paddingInlineStyles">
    <article class="cmp cmp-product-thumb nx-item-list-item">
        <div class="container cross_sell">
          <div class="row flex cross_box nx-cs-images">
            <div class="col-6 img">
              <cross-selling-item-image
                :image-urls-data="item.images | itemImages(imageUrlAccessor)"
                :alt-text="item | itemName"
                :title-text="item | itemName"
                :item-url="item | itemURL(urlWithVariationId)"
              >
              </cross-selling-item-image>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="prices cross_price nx-cs-crossprice w-100">
                  <template
                    v-if="
                      item.prices.rrp &&
                      item.prices.rrp.price.value > 0 &&
                      item.prices.rrp.price.value >
                        item.prices.default.unitPrice.value
                    "
                  >
                    <del class="crossprice" v-if="item.prices.specialOffer">
                      {{ item.prices.default.unitPrice.value.toFixed(2) }} €
                    </del>
                    <del v-else>
                      {{ item.prices.rrp.unitPrice.value.toFixed(2) }} €
                    </del>
                  </template>
                </div>

                <div class="prices w-100">
                  <template v-if="item.item.itemType === 'set'">
                    <span class="unit-price nx-cs-unit-price"
                      >{{
                        $translate("Ceres::Template.itemSetPrice", {
                          price: itemSetPrice,
                        })
                      }}€</span
                    >
                  </template>
                  <template
                    v-else-if="
                      !!item.item &&
                      item.item.salableVariationCount > 1 &&
                      $ceres.isCheapestSorting
                    "
                  >
                    <span class="unit-price nx-cs-unit-price"
                      >{{
                        $translate("Ceres::Template.categoryItemFromPrice", {
                          price: itemPrice,
                        })
                      }}€</span
                    >
                  </template>
                  <template v-else>
                    <span class="unit-price nx-cs-unit-price"
                      >{{
                        item.prices.default.unitPrice.value.toFixed(2)
                      }}€</span
                    >
                  </template>
                </div>
                <div class="w-100">
                  <slot name="text-muted">
                    <span class="vat small text-muted nx-vat-small">
                      <shipping-cost-hint-small :item="item"></shipping-cost-hint-small>
                    </span>
                  </slot>
                </div>
              </div>
            </div>
          </div>
          <div class="row nx-cs-name">
            <div class="col-12">
              <p class="nx-csitemname">{{ item | itemName }}</p>
            </div>
          </div>

          <div class="row nx-cs-buttons">
            <div class="col-12">
              <div class="graduated-prices">
                <div class="basket-preview-footer d-flex pb-3 cross_btns">
                  <a
                    :href="item | itemURL(urlWithVariationId)"
                    class="btn btn-outline-primary btn-block btn-detail nx-cs-button"
                    >Details</a
                  >
                  <span class="custom-add-to-basket-container nx-crosselling">
                    <add-to-basket
                      :variation-id="item.variation.id"
                      :is-salable="!!item.filter && item.filter.isSalable"
                      :has-children="
                        !!item.item && item.item.salableVariationCount > 1
                      "
                      :interval-quantity="
                        item.variation.intervalOrderQuantity || 1
                      "
                      :minimum-quantity="item.variation.minimumOrderQuantity"
                      :maximum-quantity="
                        !!item.variation.maximumOrderQuantity &&
                        item.variation.maximumOrderQuantity > 0
                          ? item.variation.maximumOrderQuantity
                          : null
                      "
                      :order-properties="
                        item.properties.filter(function (prop) {
                          return prop.property.isOderProperty;
                        })
                      "
                      :has-order-properties="item.hasOrderProperties"
                      :use-large-scale="false"
                      :show-quantity="false"
                      :item-url="item | itemURL(urlWithVariationId)"
                      :has-price="item | hasItemDefaultPrice"
                      :item-type="item.item.itemType"
                    >
                    </add-to-basket>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </article>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CrossSelling",
  props: {
    decimalCount: {
      type: Number,
      default: 0,
    },
    imageUrlAccessor: {
      type: String,
      default: "urlMiddle",
    },
    itemData: {
      type: Object,
    },
    disableCarouselOnMobile: {
      type: Boolean,
    },
    paddingClasses: {
      type: String,
      default: null,
    },
    paddingInlineStyles: {
      type: String,
      default: null,
    },
  },

  data() {
    return {};
  },
  created(){
    
  },
  mounted() {},
  methods: {},
  computed: {
    item() {
      return this.itemData || this.itemDataRef;
    },

    /**
     * returns itemData.item.storeSpecial
     */
    storeSpecial() {
      return this.item.item.storeSpecial;
    },

    /**
     * returns itemData.texts
     */
    texts() {
      return this.item.texts;
    },

    itemPrice() {
      return this.item.prices.default.unitPrice.value.toFixed(2);
      return this.$options.filters.specialOffer(
        this.item.prices.default.unitPrice.formatted,
        this.item.prices,
        "unitPrice",
        "formatted"
      );
    },

    itemSetPrice() {
      return this.item.prices.default.price.value.toFixed(2);
      return this.$options.filters.currency(
        this.item.prices.default.price.value,
        this.item.prices.default.currency
      );
    },

    urlWithVariationId() {
      return (
        !this.$ceres.config.item.showPleaseSelect ||
        this.$ceres.initialPleaseSelect == 0
      );
    },

    ...mapState({
      showNetPrices: (state) => state.basket.showNetPrices,
    }),
  },
};
</script>

<style lang="scss" scoped>
.graduated-prices .d-lg-none {
  display: block !important;
}
</style>
