<template>
  <div v-if="availabilityId" class="callisto-availability-icon">
    <div v-if="availabilityId > 0">
      <div class="row">
        <div class="col-12">
          <div class="item_availability">
            <template v-if="availabilityId < 5">
              <div class="icons check-svg">
                 <svg
                      xmlns:dc="http://purl.org/dc/elements/1.1/"
                      xmlns:cc="http://creativecommons.org/ns#"
                      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                      xmlns:svg="http://www.w3.org/2000/svg"
                      xmlns="http://www.w3.org/2000/svg"
                      id="svg11"
                      height="17"
                      width="16"
                      viewBox="0 0 16 17"
                      version="1.2">
                      <metadata
                        id="metadata15">
                        <rdf:RDF>
                          <cc:Work
                            rdf:about="">
                            <dc:format>image/svg+xml</dc:format>
                            <dc:type
                              rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                          </cc:Work>
                        </rdf:RDF>
                      </metadata>
                      <title
                        id="title2">ItemAvailabilityText</title>
                      <defs
                        id="defs5">
                        <image
                          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAMAAADjcdz2AAAAAXNSR0IB2cksfwAAAORQTFRFAAAAg79Pg79PPkNHPkNHPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHPkNHg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79PPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHg79Pg79Pg79Pg79PPkNHPkNHg79Pg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79PPkNHPkNHTV5JRE5IPkNHPkNHPkNHPkNHPkNHsVzUoAAAAEx0Uk5TAHSGvtC0CXf/Rf/w4iphirVM/c0GsDv29CYDLO9iTy8e5Koa7DAV2OEQi7HaLQrJPJA1/vdnuIGVxQMP5/AgWVi7n9FQZli4YHBrCDdqbxMAAACUSURBVHicVcpVEsJAFETRxh+DJwR3CO7uOvj+90MCNZlw//pUA385nHC5RR4vfOQHY4FfwRDCFAFY1PrHFDVugCZ2IplKIyMhm6M8CkULSmWqoFrTBdQb1GyhzQzoqN0e+gNShsDIBG08mc7mRAsIwJKMVmsJmy3Rbg8JOBAdYQeczpBw4Zxfb/zb3YT/dDye9l7vD/ZyFgDjJaYsAAAAAElFTkSuQmCC"
                          id="img1"
                          height="17"
                          width="16" />
                      </defs>
      
                      <g
                        id="ItemAvailabilityText">
                        <use
                          y="0"
                          x="0"
                          href="#img1"
                          id="☑ " />
                      </g>
                    </svg>
              </div>
              <div class="txt">
                <p class="green"> {{availabilityText}}</p>
              </div>

            </template>
            <template v-else>
              <div class="icons check-svg">
              <svg
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                id="svg11"
                height="17"
                width="16"
                viewBox="0 0 16 17"
                version="1.2">
                <metadata
                  id="metadata15">
                  <rdf:RDF>
                    <cc:Work
                      rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type
                        rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    </cc:Work>
                  </rdf:RDF>
                </metadata>
                <title
                  id="title2">Versandkostenfrei</title>
                <defs
                  id="defs5">
                  <image
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAMAAADjcdz2AAAAAXNSR0IB2cksfwAAAORQTFRFAAAAg79Pg79PPkNHPkNHPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHPkNHg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79PPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHg79Pg79Pg79Pg79PPkNHPkNHg79Pg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79PPkNHPkNHTV5JRE5IPkNHPkNHPkNHPkNHPkNHsVzUoAAAAEx0Uk5TAHSGvtC0CXf/Rf/w4iphirVM/c0GsDv29CYDLO9iTy8e5Koa7DAV2OEQi7HaLQrJPJA1/vdnuIGVxQMP5/AgWVi7n9FQZli4YHBrCDdqbxMAAACUSURBVHicVcpVEsJAFETRxh+DJwR3CO7uOvj+90MCNZlw//pUA385nHC5RR4vfOQHY4FfwRDCFAFY1PrHFDVugCZ2IplKIyMhm6M8CkULSmWqoFrTBdQb1GyhzQzoqN0e+gNShsDIBG08mc7mRAsIwJKMVmsJmy3Rbg8JOBAdYQeczpBw4Zxfb/zb3YT/dDye9l7vD/ZyFgDjJaYsAAAAAElFTkSuQmCC"
                    id="img1"
                    height="17"
                    width="16" />
                </defs>
     
                <g
                  id="Versandkostenfrei">
                  <use
                    y="0"
                    x="0"
                    href="#img1"
                    id="☑ " />
                </g>
              </svg>
              </div>
              <div class="txt">

                <span v-if="availabilityId == 5" class="grey">
                 <p class="nx-text green">Artikel bestellbar und in Kürze versandbereit</p>
                 <p class="nx-text-small">Der verbindliche Versandtermin wird innerhalb 24h per eMail mitgeteilt.</p>
                </span>
                <p v-if="availabilityId > 5" class="grey">
                 {{availabilityText}}
                </p>


              </div>

            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemAvailabilityText",
  props: {
    availability: null,
    displayText: false,
    alignment: {
      type: String,
      default: "right",
    },
  },

  computed: {
    availabilityId() {
      if (this.availability) return this.availability.id;

      return this.$store.getters.currentItemVariation.variation.availability.id;
    },
    availabilityText() {
      if (this.availability) return this.availability.names.name;

      return this.$store.getters.currentItemVariation.variation.availability.names.name;
    },
  },
};
</script>

<style scoped>
.nx-text {
    font-size: 1rem !important;
    line-height: 25px!important;
}
tspan { white-space:pre }

</style>


