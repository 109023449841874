<template>
  <div v-if="availabilityId" class="callisto-availability-icon">
    <div v-if="availabilityId > 0">
            <span v-if="availabilityId < 2" class="availabilityIcon availabilityDetails-3 available-1 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span v-if="availabilityId == 2" class="availabilityIcon availabilityDetails-3 available-2 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span v-if="availabilityId == 3" class="availabilityIcon availabilityDetails-3 available-3 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span v-if="availabilityId == 4" class="availabilityIcon availabilityDetails-3 available-4 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span v-if="availabilityId == 5" class="availabilityIcon availabilityDetails-3 available-5 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span v-if="availabilityId == 6" class="availabilityIcon availabilityDetails-3 available-6 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span v-if="availabilityId == 7" class="availabilityIcon availabilityDetails-3 available-7 margin-right-05" :class="{'pull-left': alignment === 'left', 'pull-right': alignment === 'right'}">
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
            </span>
      <span class="availabilityName" v-if="displayText">{{ name }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      availability: null,
      displayText: false,
      alignment: {
        type: String,
        default: "right"
      }
    },

    computed: {
      availabilityId() {
        if (this.availability) return this.availability.id;

        return this.$store.getters.currentItemVariation.variation.availability.id;
      },

      name() {
        if (this.availability) return this.availability.names.name;

        return this.$store.getters.currentItemVariation.variation.availability.names.name;
      },
    },
  }
</script>

<style scoped>
  .availabilityName {
    font-size: 12px;
  }

  .availabilityIcon {
    min-height: 26px;
  }

  .availabilityIcon {
    display: block;
    height: 16px;
    width: 16px;
    padding-top: 3px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 0px;
    overflow: hidden;
  }

  .availabilityIcon.availabilityDetails-3 span {
    height: 6px;
  }

  .availabilityIcon span {
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-color: rgba(0, 0, 0, .2);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 0px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(255, 255, 255, .5) inset;
    -moz-box-shadow: 0 1px 2px 0 rgba(255, 255, 255, .5) inset;
    box-shadow: 0 1px 2px 0 rgba(255, 255, 255, .5) inset;
  }

  .availabilityIcon span+span {
    margin-top: -1px;
  }

  .availabilityDetails-1.available-1 .first,
  .availabilityDetails-1.available-2 .first,
  .availabilityDetails-1.available-3 .first,
  .availabilityDetails-1.available-4 .first,
  .availabilityDetails-2.available-1 .first,
  .availabilityDetails-2.available-1 .second,
  .availabilityDetails-2.available-2 .first,
  .availabilityDetails-2.available-2 .second,
  .availabilityDetails-2.available-3 .second,
  .availabilityDetails-2.available-4 .second,
  .availabilityDetails-3.available-1 .first,
  .availabilityDetails-3.available-1 .second,
  .availabilityDetails-3.available-1 .third,
  .availabilityDetails-3.available-2 .second,
  .availabilityDetails-3.available-2 .third,
  .availabilityDetails-3.available-3 .third {
    background-color: #3b0;
  }

  .availabilityDetails-1.available-10 .first,
  .availabilityDetails-1.available-9 .first,
  .availabilityDetails-2.available-10 .first,
  .availabilityDetails-2.available-10 .second,
  .availabilityDetails-2.available-7 .first,
  .availabilityDetails-2.available-8 .first,
  .availabilityDetails-2.available-9 .first,
  .availabilityDetails-2.available-9 .second,
  .availabilityDetails-3.available-5 .first,
  .availabilityDetails-3.available-6 .first,
  .availabilityDetails-3.available-6 .second,
  .availabilityDetails-3.available-7 .first,
  .availabilityDetails-3.available-7 .second,
  .availabilityDetails-3.available-7 .third,
  .availabilityDetails-3.available-8 .second,
  .availabilityDetails-3.available-8 .third,
  .availabilityDetails-3.available-9 .third {
    background-color: #f11;
  }

  .availabilityDetails-1.available-5 .first,
  .availabilityDetails-1.available-6 .first,
  .availabilityDetails-1.available-7 .first,
  .availabilityDetails-1.available-8 .first,
  .availabilityDetails-2.available-3 .first,
  .availabilityDetails-2.available-4 .first,
  .availabilityDetails-2.available-5 .first,
  .availabilityDetails-2.available-5 .second,
  .availabilityDetails-2.available-6 .first,
  .availabilityDetails-2.available-6 .second,
  .availabilityDetails-2.available-7 .second,
  .availabilityDetails-2.available-8 .second,
  .availabilityDetails-3.available-2 .first,
  .availabilityDetails-3.available-3 .first,
  .availabilityDetails-3.available-3 .second,
  .availabilityDetails-3.available-4 .first,
  .availabilityDetails-3.available-4 .second,
  .availabilityDetails-3.available-4 .third,
  .availabilityDetails-3.available-5 .second,
  .availabilityDetails-3.available-5 .third,
  .availabilityDetails-3.available-6 .third {
    background-color: #fc3;
  }

  .margin-right-05 {
    margin-right: 6px;
  }
</style>
