<template>
    <a :href="itemUrl">
        <img class="img-fluid" :src="imageUrls | itemImage" :alt="getAltText(imageUrls[0])" :title="getImageName(imageUrls[0])">
    </a>
</template>

<script>

    export default {

        name: "CrossSellingItemImage",

        props: {
            imageUrlsData:
                {
                    type: Array
                },
            itemUrl:
                {
                    type: String
                },
            altText:
                {
                    type: String
                },
            titleText:
                {
                    type: String
                },
            template:
                {
                    type: String
                }
        },

        computed:
            {
                imageUrls()
                {
                    return this.imageUrlsData.sort((imageUrlA, imageUrlB) =>
                    {
                        if (imageUrlA.position > imageUrlB.position)
                        {
                            return 1;
                        }
                        if (imageUrlA.position < imageUrlB.position)
                        {
                            return -1;
                        }

                        return 0;
                    });
                }
            },

        methods:
            {

                getAltText(image)
                {
                    const altText = image && image.alternate ? image.alternate : this.altText;

                    return altText;
                },

                getImageName(image)
                {
                    const altText = image && image.name ? image.name : this.titleText;

                    return altText;
                }
            }
    }
</script>
