<template>
  <div class="crossSellingShowMore wwcrossSellingShowMore">
    <template v-if="showMore">
      <slot name="showmore"></slot>
      <a @click="itemsShowMore" class="showmore_cross">
        weniger anzeigen <i class="fas fa-chevron-up"></i
      ></a>
    </template>
    <template v-if="!showMore">
      <slot name="showless"></slot>
      <a @click="itemsShowMore"  v-if="toShow" class="showless_cross">
        weitere anzeigen <i class="fas fa-chevron-down"></i
      ></a>
    </template>
  </div>
</template>

<script>
export default {
  name: "CrossSellingShowMore",
  props: {
    toShow: {
      type: Boolean,
    }
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    itemsShowMore() {
      this.showMore = !this.showMore;
    },
    itemsShowLess() {
      this.showMore = this.showMore;
    },
  },
};
</script>

<style lang="scss" scoped>
.crossSellingShowMore.wwcrossSellingShowMore {min-height:250px;}

.showLess.row , .showMore.row  {
    min-height: 225px;
}
</style>
