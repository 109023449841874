<template>
  <div class="shipping-cost-hint">
    <div class="vat small text-muted">
      <div :class="{'ww-small-hint': hintStyle === 'ads'}">
        <span>{{ shippingHint }}</span>
        <div class="nxtooltip" >
          <div  class="nxtooltip-link" data-toggle="modal"
          data-target="#nxShippingModal" href="https://net-xpress.de/ueber-uns/versand">{{ tooltip }}</div>
          <span class="nxtooltiptext" :class="{'large-tooltip': !isFree}" v-html="tooltiptext">

          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: null,
    hintStyle: {
      type: String,
      default: "category-item"
    }
  },
  data() {
    return {
      tooltip: "",
      tooltiptext: "",
      isFree: false
    };
  },
  computed: {
    basketAmount() {
      return this.$store.state.basket.data.basketAmountNet;
    },

    shippingHint() {
      let price;
      let vat;


      if (this.item) {
        price = this.item.prices.default.unitPrice.value;
        vat = (1 + (this.item.prices.default.vat.value/100)).toFixed(2);
      } else {
        vat = (1 + (this.$store.getters.variationGraduatedPrice.vat.value/100)).toFixed(2);
        price = this.$store.getters.variationGraduatedPrice.price.value;
      }
        
      if (this.basketAmount + price > 49.9) {
        console.log(price)
        this.isFree = true;
        this.tooltip = "versandkostenfrei (D)";
        this.tooltiptext =
          "Mit Kauf dieses Artikels liegt der Gesamtwarenwert bei oder über 49,90 &euro; netto, daher ist die Lieferung innerhalb Deutschlands versandkostenfrei.";

        if (this.hintStyle === "ads")
          return (
            "+ MwSt. 19%, "
          );

        return "* zzgl. Mwst. ";
      }

      this.tooltip = "Versandkosten";
      this.tooltiptext =
        "Ihr Warenkorbwert liegt unter 49,90 &euro; netto, es fallen innerhalb Deutschlands pauschal 6,90 &euro; netto Versandkosten an.<hr>Fügen Sie einfach " +
        Math.ceil((49.9 - this.basketAmount) / price) +
        " Stück dieses Artikels oder andere Waren für 49.90 &euro; netto für die versandkostenfreie Lieferung in Deutschland hinzu.";

      if (this.hintStyle === "ads")
        return (
          "+ MwSt. 19%, zzgl. "
        );

      return "* zzgl. MwSt. zzgl. ";
    }
  }
};
</script>

<style lang="scss" scoped>
.nxtooltip {
  visibility: visible;
  position: relative;
  display: inline-block;
}

.nxtooltip a {
  text-decoration: none;
  color: #6c757d !important;
}

.nxtooltip .nxtooltiptext {
  visibility: hidden;
  text-align: center;
  border-radius: 0px;
  padding: 5px 0;
  width: 200px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0%;
  margin-left: -60px;
  background: black;
  color: white;
  white-space: normal;
  line-height: 1.5;

  &.large-tooltip {
    left: -50%;
  }
}

.shipping-cost-hint:hover .nxtooltiptext {
  visibility: visible;
}
.nxtooltip:hover .nxtooltiptext {
  visibility: visible;
}

.ww-small-hint {
  font-size: 12px;

  a {
    color: #fa7d19 !important;
  }
}

.nxtooltip-link
  {
    color: #fa7d19 !important;
  }
</style>
