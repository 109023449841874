<template>
  <span class="trusted-shops-rating">
    <span v-if="rating">{{ rating }} / 5.00</span>
  </span>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'trusted-shops-rating',

    props: {},

    data() {
      return {
        rating: null
      }
    },

    created(){
      this.getTrustedShopsData();
    },

    methods: {
      async getTrustedShopsData(){
        const { data } = await axios.get(`/rest/widgetpack/trustedshops/shop-rating`);
        console.log({rating: data});

        this.rating = data.rating.data.shop.qualityIndicators.reviewIndicator.overallMark;
      }
    },

  }
</script>

<style lang="scss" scoped>
  .trusted-shops-rating {

  }
</style>
