import ItemPropertyList from "./Components/ItemPropertyList";
import UploadList from "./Components/UploadList.vue";
import PrintProductsLeft from "./Components/PrintProductsLeft.vue";
import ItemDetails from "./Components/ItemDetails";
import GraduatedPrices from "./Components/GraduatedPrices";
import AvailabilityIcon from "./Components/AvailabilityIcon";
import ShippingCostHint from "./Components/ShippingCostHint";
import ShippingCostHintSmall from "./Components/ShippingCostHintSmall";
import BlogPosts from "./Components/BlockPosts";
import TrustedShopsRating from "./Components/TrustedShopsRating";
import CrossSelling from "./Components/CrossSelling";
import CrossSellingItemImage from "./Components/CrossSellingItemImage";
import CrossSellingShowMore from "./Components/CrossSellingShowMore";
import VerticalItemImageCarousel from "./Components/VerticalItemImageCarousel";
import ItemDetailsShippingCostHint from "./Components/ItemDetailsShippingCostHint";
import ItemAvailabilityText from "./Components/ItemAvailabilityText";
import ItemPropertieDataSheet from "./Components/ItemPropertieDataSheet";
import ItemPropertiePrintTemplate from "./Components/ItemPropertiePrintTemplate";
import RegistrationNetxpress from "./Components/RegistrationNetxpress";
import AddressInputGroupNetxpress from "./Components/AddressInputGroupNetxpress";
import UpsShipmentTracking from "./Components/UpsShipmentTracking";
import ReferrerName from "./Components/ReferrerName";
import VModal from "vue-js-modal";

Vue.use(VModal);

Vue.component("ww-upload-list", UploadList);
Vue.component("print-products-left", PrintProductsLeft);
Vue.component("item-details", ItemDetails);
Vue.component("item-property-list", ItemPropertyList);
Vue.component("graduated-prices", GraduatedPrices);
Vue.component("availability-icon", AvailabilityIcon);
Vue.component("shipping-cost-hint", ShippingCostHint);
Vue.component("shipping-cost-hint-small", ShippingCostHintSmall);
Vue.component("blog-posts", BlogPosts);
Vue.component("trusted-shops-rating", TrustedShopsRating);
Vue.component("cross-selling", CrossSelling);
Vue.component("cross-selling-item-image", CrossSellingItemImage);
Vue.component("cross-selling-show-more", CrossSellingShowMore);
Vue.component("vertical-item-image-carousel", VerticalItemImageCarousel);
Vue.component("item-details-shipping-cost-hint", ItemDetailsShippingCostHint);
Vue.component("item-availability-text", ItemAvailabilityText);
Vue.component("item-propertie-datasheet", ItemPropertieDataSheet);
Vue.component("item-propertie-printtemplate", ItemPropertiePrintTemplate);
Vue.component("registration-netxpress", RegistrationNetxpress);
Vue.component("address-input-group-netxpress", AddressInputGroupNetxpress);
Vue.component("ups-shipment-tracking", UpsShipmentTracking);
Vue.component("referrer-name", ReferrerName);

Vue.methodTest = function() {
  console.log("From Vue..");
};




 