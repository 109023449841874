<template>
  <span v-if="referrer">{{ referrer.name }}</span>
</template>

<script>
import axios from "axios";

export default {
  name: "referrer-name",

  props: {
    referrerId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      referrerId: 0,
      referrer: null,
    };
  },

  created() {
    this.fetchReferrer();
  },

  methods: {
    async fetchReferrer() {
      const response = await axios.get("/rest/nx-theme/referrer-by-id?referrerId=" + this.referrerId);
      if (!response.status === 200) return;
      this.referrer = response.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
