<template>
  <div class="graduated-prices">
    <table>
      <tbody>
      <tr>
        <td v-for="price in prices" v-if="price.minimumOrderQuantity != 1">
          <div class="grad-price-container">
            <p class="grad-quantity">ab {{ price.minimumOrderQuantity }}</p>
            <p class="grad-price"> {{ price.unitPrice.formatted }}</p>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <p v-if="showDeliveryText" class="small text-center mb-0" style="color: grey">versandkostenfrei ab 49,90 € (D)</p>
  </div>
</template>

<script>
    export default {
        props: {
            graduatedPrices: null,
            showDeliveryText: false
        },
        computed: {
            prices() {
                if (!this.graduatedPrices) {
                  return this.$store.getters.currentItemVariation.prices.graduatedPrices.sort((priceA, priceB) => {
                    return priceA.minimumOrderQuantity - priceB.minimumOrderQuantity;
                  });
                }

                return [...this.graduatedPrices].sort((priceA, priceB) => {
                    return priceA.minimumOrderQuantity - priceB.minimumOrderQuantity;
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
  table {
    display: table;
    border-color: grey;
    width: 102%;
    margin-left: -1%;
    border-spacing: 2px 0;
    border-collapse: separate;
  }

  caption {
    display: table-caption;
    text-align: center;
    font-weight: 700;
    margin-left: 2px;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  td {
    padding: 5px 0;
    background-color: gray;
    opacity: 0.7;
    color: #fff;
    text-align: center;
  }

  .grad-price-container {
    background-color: grey;
    padding: 1px;
    margin: 1px;

    p {
      margin: 0;
      line-height: 1.5;
    }

    p.grad-quantity {
      font-weight: bold;
    }

    p.grad-price {}
  }
</style>
