<template>
  <div
    class="upload-list"
    v-if="printItems.length > 0"
    style="margin-top: 40px"
  >
    <div class="panel panel-default mt-4">
      <div class="panel-heading">
        <h3 class="panel-title">Druckdatenupload</h3>
      </div>

      <div class="panel-body">
        <h4>Bitte laden sie die Druckdaten zu folgenden Produkten hoch</h4>
        <hr />
        <p>
          Ihre Bestellung enthält Produkte zu denen ein Druckdatenupload
          notwendig ist. Sie können die entsprechenden Daten direkt hochladen
          oder dieses später in der Auftragsübersicht nachholen.
        </p>

        <div class="print-items" v-for="(item, index) in printItems">
          <div class="panel panel-default printProduct">
            <div class="panel-heading">
              <div class="panel-title">
                <strong>{{ item.orderItemName }}</strong>
              </div>
            </div>
            <div class="panel-body">
              <div class="row padding-top-1 padding-bottom-1">
                <div class="col-sm-4 col-xs-12">
                  <img :src="item.imageUrl" alt="" style="margin-left: 3%" />
                </div>
                <div class="col-sm-2 col-xs-12">
                  <div class="bold">Artikel-Nr.</div>
                  <div>{{ item.variationNumber }}</div>
                </div>
                <div class="col-sm-2 col-xs-12">
                  <div class="bold">Menge</div>

                  <div
                    v-if="
                      item.quantity - missingPrintItems[item.itemVariationId] >=
                      item.quantity
                    "
                    class="text-success"
                  >
                    <span
                      >{{
                        item.quantity -
                          missingPrintItems[item.itemVariationId] || 0
                      }}
                      von {{ item.quantity }} hochgeladen</span
                    >
                    <span>&#10003;</span>
                  </div>

                  <div v-else>
                    {{
                      item.quantity - missingPrintItems[item.itemVariationId] ||
                      0
                    }}
                    von {{ item.quantity }}
                    hochgeladen
                  </div>
                </div>
                <div class="col-sm-4 col-xs-12">
                  <div class="bold">Druckdaten</div>
                  <div>
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="showModal(index)"
                      :disabled="missingPrintItems[item.itemVariationId] == 0"
                      :class="{
                        'btn btn-primary':
                          item.quantity -
                            missingPrintItems[item.itemVariationId] >=
                          item.quantity,
                      }"
                    >
                      <span class="glyphicon glyphicon-floppy-open"></span>
                      <span
                        v-if="
                          item.quantity -
                            missingPrintItems[item.itemVariationId] >=
                          item.quantity
                        "
                        >Upload vollständig</span
                      >
                      <span v-else class="button-text">Upload starten</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      name="upload-modal"
      height="auto"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="d-flex justify-content-between header-holder">
        <h3>Druckdatenupload</h3>
        <p class="text-right" style="cursor: pointer" @click="closeModal">
          <i class="fas fa-times"></i>
        </p>
      </div>

      <div v-if="fileIsUploading" class="modal-body">
        <p class="upload-info-text text-center mb-3">
          Der Upload der Datei(en) läuft
        </p>
        <div class="loader spinner"></div>
        <p class="upload-lower-text text-center mt-3">
          Bitte beachten Sie, dass der Upload je nach Dateigröße mehrere Minuten
          in Anspruch nehmen kann!
        </p>
      </div>

      <div
        v-else-if="fileUploadFeedbackMessage"
        class="modal-body upload-feedback"
      >
        <p
          v-bind:class="{
            'text-danger': !fileUploadSuccess,
            'text-success': fileUploadSuccess,
          }"
        >
          {{ fileUploadFeedbackMessage }}
        </p>
      </div>

      <div v-else class="modal-body">
        <p>
          Bitte laden Sie zu diesem Artikel die entsprechenden Motive auf
          unseren Server. Bei mehreren Motiven je Artikel wählen Sie bitte die
          zu druckende Anzahl entsprechend aus.
        </p>
        <p>Erlaubte Formate: jpg, pdf, max. Gesamtuploadgröße: 150 MB</p>

        <div
          class="row mb-1"
          v-for="(image, index) in images"
          v-if="currentItem"
        >
          <div
            class="col-sm-3 d-flex flex-col justify-content-start align-items-start"
          >
            <label class="mb-0">Motiv {{ index + 1 }}, Anzahl</label>
            <input
              class="file-quantity-input"
              type="number"
              min="1"
              :value="image.quantity"
              @change="updateQuantityFor($event, index)"
              style="max-width: 85px"
            />
          </div>
          <div
            class="col-sm-8 d-flex flex-col justify-content-start align-items-start"
          >
            <label class="upload-input-label">
              <span>Motiv {{ index + 1 }}, Datei</span>
              <div class="upload-input-box">
                <span v-if="!image.file">Keine Datei Ausgewählt</span>
                <span v-if="image.file">{{ image.file.name }}</span>
                <span class="upload-button">Durchsuchen</span>
              </div>
              <input
                style="display: none"
                class="custom-file"
                type="file"
                @change="setFileFor($event, index)"
              />
            </label>
          </div>
          <div class="col-sm-1" @click="removeImage(index)">
            <i class="fas fa-times"></i>
          </div>
        </div>

        <p
          v-if="tooManySelected"
          class="text-danger text-left"
          style="line-height: 1.4"
        >
          Die bestellte Anzahl dieses Produktes wurde überschritten.
          <br />
          <span class="font-weight-bold"
            >Bitte verringern Sie die Anzahl um
            {{ itemsSelected - currentItem.quantity }}
          </span>
        </p>

        <div class="button-holder">
          <button
            :disabled="tooManySelected"
            @click="addImage"
            class="btn btn-primary mt-2 mb-3"
          >
            Weiteres Motiv hinzufügen
          </button>
        </div>

        <strong
          >Bitte beachten Sie, dass der Upload je nach Dateigröße mehrere
          Minuten in Anspruch nehmen kann!</strong
        >
      </div>
      <div class="bottom-buttons d-flex justify-content-end align-items-center">
        <button
          v-if="fileUploadFeedbackMessage && !fileUploadSuccess"
          class="btn btn-default mr-2"
          @click="goBack"
        >
          <i class="fas fa-arrow-left mr-1"></i>Zurück
        </button>
        <button
          v-if="fileUploadFeedbackMessage"
          class="btn btn-default mr-2"
          @click="closeModal"
        >
          <i class="fas fa-times mr-1"></i>Schließen
        </button>
        <button v-else class="btn btn-default mr-2" @click="closeModal">
          <i class="fas fa-times mr-1"></i>Abbrechen
        </button>

        <div
          v-if="!fileUploadSuccess && currentItem && !fileUploadFeedbackMessage"
        >
          <button
            :disabled="
              tooManySelected || fileIsUploading || !allProductsHaveBeenFilled
            "
            @click="uploadImages"
            class="btn btn-primary"
          >
            <span v-if="tooManySelected || !allProductsHaveBeenFilled"
              ><i class="fas fa-exclamation mr-1"></i>Ungültige Anzahl</span
            >
            <span v-else><i class="fas fa-check mr-1"></i>Upload starten</span>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import _get from "lodash.get";

export default {
  name: "upload-list",

  props: {
    order: {
      default: null,
      type: Object,
    },
  },

  computed: {
    currentItem() {
      return this.printItems[this.currentItemIndex];
    },

    itemsSelected() {
      return this.images.reduce(
        (total, current) => total + current.quantity,
        0
      );
    },

    tooManySelected() {
      // Sum up all quantities in the currently open modal and check if
      // that sum exceeds the total quantity contained in the order
      // to make sure that no more products are "filled" then have been ordered
      if (!this.currentItem) return false;
      return this.itemsSelected > this.currentItem.quantity;
    },

    allProductsHaveBeenFilled() {
      if (!this.currentItem) return false;
      return this.itemsSelected === this.currentItem.quantity;
    },
  },

  data() {
    return {
      printItems: [],
      missingPrintItems: [],
      images: [
        {
          file: null,
          quantity: 1,
        },
      ],
      currentItemIndex: null,
      fileIsUploading: false,
      fileUploadSuccess: false,
      fileUploadFeedbackMessage: null,
    };
  },

  async created() {
    this.printItems = await this.determinePrintItems();
    this.fetchImagesForPrintItems();
    this.missingPrintItems = await this.fetchPrintProductsLeft();
  },

  methods: {
    async determinePrintItems() {
      let variationIdString = this.order.orderItems.reduce((string, item) => {
        if (item.orderItemName === "Versandkosten") return string;
        if (item.itemVariationId === 0) return string;
        if (string.length && item.itemVariationId != 0)
          return (string += "," + item.itemVariationId);
        return item.itemVariationId != 0
          ? (string += item.itemVariationId)
          : string;
      }, "");

      const { data } = await axios.get(
        `https://www.net-xpress.de/rest/Netxpress/print-items?variationIds=${variationIdString}`
      );

      let printItems = this.order.orderItems.filter((orderItem) => {
        let matchingItem = data.find(
          (printItem) => printItem.variationId == orderItem.itemVariationId
        );
        if (!matchingItem) return false;

        if (orderItem.orderItemName === "Versandkosten") return false;
        if (orderItem.itemVariationId === 0) return false;

        orderItem.variationNumber = matchingItem.variationNumber;
        return orderItem;
      });

      return printItems.map((orderItem) => {
        let matchingItem = data.find(
          (printItem) => printItem.variationId == orderItem.itemVariationId
        );
        if (!matchingItem) return orderItem;

        orderItem.variationNumber = matchingItem.variationNumber;
        return orderItem;
      });
    },

    async fetchImagesForPrintItems() {
      let printItemsWithImages = [];

      for (let printItem of this.printItems) {
        const { data } = await axios.get(
          `https://www.net-xpress.de/rest/Netxpress/variation-images?variationId=${printItem.itemVariationId}`
        );
        if (!data) continue;

        printItem.imageUrl = data[0].urlPreview;
        printItemsWithImages.push(printItem);
      }

      this.printItems = printItemsWithImages;
    },

    showModal(index) {
      this.currentItemIndex = index;
      this.$modal.show("upload-modal");
    },

    async closeModal() {
      this.$modal.hide("upload-modal");

      if (this.fileUploadSuccess) {
        let productsLeft = await this.fetchPrintProductsLeft();
        this.missingPrintItems = productsLeft;
      }

      this.currentItemIndex = null;
      this.fileUploadSuccess = false;
      this.fileUploadFeedbackMessage = null;
    },

    goBack() {
      this.fileUploadSuccess = false;
      this.fileUploadFeedbackMessage = null;
    },

    addImage() {
      this.images.push({
        file: null,
        quantity: 1,
      });
    },

    removeImage(index) {
      this.images.splice(index, 1);
    },

    setFileFor(event, index) {
      this.images[index].file = event.target.files[0];
    },

    updateQuantityFor(event, index) {
      let quantity = Number(event.target.value);

      this.images = this.images.map((image, i) => {
        if (i === index) {
          image.quantity = quantity;
        }
        return image;
      });
    },

    async uploadImages() {
      if (!this.allProductsHaveBeenFilled) return;
      this.fileIsUploading = true;

      let orderData = {};
      orderData[this.currentItem.itemVariationId] = [];

      for (let image of this.images) {
        let fileUrl = await this.uploadFile(image);
        if (!fileUrl) return;

        orderData[this.currentItem.itemVariationId].push({
          fileUrl,
          quantity: image.quantity,
        });
      }

      await this.createOrderData(orderData);

      this.fileIsUploading = false;
      this.fileUploadSuccess = true;
      this.fileUploadFeedbackMessage =
        "Die Übertragung wurde erfolgreich abgeschlossen.";
      this.images = [
        {
          file: null,
          quantity: 1,
        },
      ];
    },

    async uploadFile(image) {
      const toBase64 = (image) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(image.file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      // Prepare request
      let formData = new FormData();
      formData.append("File", image.file);
      formData.append("OrderId", this.order.id);
      formData.append("ArticleNr", this.currentItem.variationNumber);
      formData.append("TotalQuantity", this.currentItem.quantity);
      formData.append("Quantity", image.quantity);

      // Send file to net-xpress.com to be uploaded
      try {
        let response = await axios.post(
          "https://net-xpress.com/uploadscript/upload-from-ceres.php",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        return response.data;
      } catch (error) {
        this.fileIsUploading = false;
        this.fileUploadSuccess = false;
        this.fileUploadFeedbackMessage = _get(
          error,
          "response.data",
          "Es gab einen Fehler bei der Übertragung der Datei(en)"
        );
      }
    },

    async createOrderData(orderData) {
      let orderDataResponse = await axios.post(
        "https://www.net-xpress.de/rest/jd/orderData",
        {
          orderId: this.order.id,
          sortedBy: "variationId",
          data: JSON.stringify(orderData),
        }
      );
    },

    async fetchPrintProductsLeft() {
      const { data } = await axios.get(
        `/rest/jd/uploads_left?orderId=${this.order.id}`
      );

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.v--modal-overlay {
  z-index: 2500 !important;
}

.bold {
  font-weight: bold;
}

.print-items {
  padding-top: 10px;
  padding-bottom: 10px;

  .panel {
    border: 1px solid #e3e3e3;
    padding-bottom: 15px;
    border-radius: 2px;

    .panel-heading {
      background: #eee;
      padding: 7px 10px;
      margin-bottom: 8px;
    }
  }
}

.header-holder p {
  margin-top: 5px;
}

.upload-info-text {
  color: #555;
  font-weight: bold;
}

.upload-lower-text {
  color: dimgrey;
}

.upload-feedback p {
  margin: 20px 0 5px 0;
  font-size: 16px;
}

.upload-input-label {
  width: 100%;
  cursor: pointer;

  .upload-input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 2px;

    span {
      white-space: nowrap;
      overflow: hidden;
      max-width: 66.6%;
    }

    .upload-button {
      padding: 4px 10px;
      margin-left: 15px;
      background-color: #fa7d19;
      border-radius: 0 2px 2px 0;
      color: white;
    }
  }
}

.file-quantity-input {
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 4px 12px;
}
</style>
