<template >
<div >
  <div v-if="DataSheetURL != ''" class="nx-DataSheet">
    <div class="row">
      <div class="col-12 nx-DataSheet-box">
        <div>
          <template >
            <div>

               <a :href="DataSheetURL" target="_blank"> <i class="fas fa-file-pdf"></i><div class="DataSheet-text">Anleitung</div></a>
            </div>
          </template>

        </div>
      </div>

    </div>
     <hr>
  </div>
</div>
</template>

<script>
export default {
  name: "ItemPropertieDataSheet",
  props: {
    item: null,
  },
 data() {
    return {
      showLink:false
    };
  },
  computed: {
    DataSheetURL() {
     let url = "";
     let probs = null;
     let variation = this.$store.getters.currentItemVariation;
     if(variation.hasOwnProperty('variationProperties')){
        probs = variation.variationProperties[0].properties;

        probs.forEach(element => {
        if (element.id == 5)
          {
          url = element.values.value;

          this.showLink = true;
          }

        });

    }


      return url;

  }
  },

};
</script>

<style lang="scss" scoped>


</style>
