<template>
  <ul class="nx-item-properties">
    <li v-for="(propertyText, index) of productProperties" class="nx-item-description-property"
        v-html="propertyText"></li>
  </ul>
</template>

<script>
  export default {
    props: {
      itemData: null,
      rawPropertyString: null
    },

    computed: {
      propertyString() {
        if (this.itemData) return this.itemData.item.free8;
        if (this.rawPropertyString) return this.rawPropertyString;

        return "";
      },

      productProperties() {
        return this.propertyString.split('\n');
      }
    },
  }
</script>

<style lang="scss" scoped>
  .nx-item-description-property {
    font-size: 12px;
    margin-bottom: 4px;
  }
</style>
