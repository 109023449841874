<template>
  <div id="print-products-left-container">
    <div class="pr-1" v-if="onlyIcon">
      <svg width="1.2em" height="1.2em" viewBox="0 1 16 16" class="bi bi-file-earmark-arrow-up-fill" :fill="iconColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z"/>
      </svg>
    </div>

    <div class="button-spacer">
      <a v-if="!onlyIcon" :href="`/confirmation/${orderId}`"
         class="btn btn-appearance btn-block"
         :class="{'btn-success' : productsLeft === 0 && orderContainsPrintItems, 'btn-default' : productsLeft === 0 && !orderContainsPrintItems, 'btn-primary' : productsLeft > 0}">
        <span class="pr-1">
          <svg width="1em" height="1em" viewBox="0 1 16 16" class="bi bi-file-earmark-arrow-up-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z"/>
          </svg>
        </span>
        <span v-if="productsLeft === 0 && orderContainsPrintItems">Druckdatenupload abgeschlossen</span>
        <span v-if="productsLeft === 0 && !orderContainsPrintItems">Ausführliche Bestellübersicht</span>
        <span v-if="productsLeft > 0">{{ productsLeft }} Druckdaten hochladen</span>
      </a>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "print-products-left",
    props: {
      orderId: null,
      onlyIcon: false,
    },

    data() {
      return {
        productsLeft: 0,
        orderConfirmationUrl: null,
        orderContainsPrintItems: false
      }
    },

    async created() {
      await this.fetchProductsLeftForOrder();
      console.log({color: this.iconColor});
    },

    computed: {
      iconColor() {
        if (this.productsLeft === 0 && this.orderContainsPrintItems) return "#28a745";
        if (this.productsLeft === 0 && !this.orderContainsPrintItems) return "transparent";

        return "#FA7D19";
      }
    },

    methods: {
      async fetchProductsLeftForOrder() {
        const { data } = await axios.get(`/rest/jd/uploads_left?orderId=${this.orderId}`);

        if (Object.keys(data).length === 0) {
          this.orderContainsPrintItems = false;
          return 0;
        }

        this.orderContainsPrintItems = true;
        let uploadsMissingForOrder = 0;

        // Sum up all quantities from all uploads for each item
        for (let variationId in data) {
          if (!data.hasOwnProperty(variationId)) continue;
          uploadsMissingForOrder += data[variationId];
        }

        this.productsLeft = uploadsMissingForOrder;
      }
    }
  }
</script>

<style scoped>

</style>
