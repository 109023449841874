<template>
    <dl class="technical-data-list clearfix" :class="{'small-data-list': type === 'list-item'}" title="Technische Daten">
      <template v-for="(specValue, specName, index) in specs" v-if="type !== 'list-item' || index < 3">
        <dt>{{ specName }}</dt>
        <dd>{{ specValue }}</dd>
      </template>
    </dl>
</template>

<script>
  import axios from 'axios';
  export default {
    props: {
      itemData: {
        type: Object,
        default: null,
      },
      type: {
        type: String,
        default: 'list-item'
      }
    },

    data(){
      return {
        techSpecString: null,
        specs : {}
      }
    },
    mounted(){
      this.techSpecs();
    },
    methods:{
      techSpecs() {
          console.log("start")
          console.log(this.itemData)
          console.log("end")
        axios.get('https://www.net-xpress.de/rest/nx-theme/item?id='+this.itemData.item.id+'&pluginSetPreview=JDJ5JDEwJG4xdkNLNHBzWmNvU25qT0F5bWVDZk9LYlJsdUEuRGRHQ2dUSkhpWk5jVTBLTFhOZ002blVh')
                .catch(err => {
                  console.info('Error fetching techSpec', err);
                  return '';
                })
                .then(response => {
                  let self = this;
                  this.techSpecString = response.data;
                  let parts = this.techSpecString.split('\n');
                  let data = {};

                  parts.forEach(function (property) {
                    let propertyString = property.split(':');
                    if (!propertyString[1]) return;
                      data[propertyString[0]] = propertyString[1].trim();
                  });
                  this.specs = data;
                });
      }
    },
  }
</script>

<style lang="scss" scoped>
  .clearfix {
    *zoom: 1;
  }

  .clearfix:before,
  .clearfix:after,
  .catchMarginBefore:before,
  .catchMarginAfter:after {
    display: table;
    line-height: 0;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }

  .technical-data-list > dt,
  .technical-data-list > dd {
    line-height: 1.4 !important;
    border-bottom: 1px solid #ddd;
    float: left;
    margin-bottom: 6px;
    padding-bottom: 6px;
  }

  .technical-data-list.small-data-list > dt,
  .technical-data-list.small-data-list > dd {
    font-size: 10px;
    border-bottom: 0;
    margin-bottom: 2px;
    padding-bottom: 2px;
  }

  .technical-data-list > dt:last-child
  .technical-data-list > dd:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }

  .technical-data-list.small-data-list > dt:last-child
  .technical-data-list.small-data-list > dd:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }

  .technical-data-list > dt {
    width: 20%;
    clear: left;
  }

  .technical-data-list > dd {
    width: 40%;
  }

  .technical-data-list.small-data-list > dt {
    width: 45%;
    clear: left;
  }

  .technical-data-list.small-data-list > dd {
    width: 55%;
  }

  /* /nx: texh data def list */

  /* nx: techdata on mouseover*/
  .touch .techDataBox {
    display: none;
  }

  .techDataBox {
    /* line height 24px => 3-4 lines = 96px*/
    height: 96px;
    overflow-y: hidden;
    padding: 0 10px;
  }

  .techDataBox th, td {
    vertical-align: top;
    text-align: left;
  }

  .techDataBox th {
    font-family: "Open Sans condensed";
    padding-right: 6px;
  }

  .techDataBox td {
    display: block;
    max-height: 48px;
    overflow: hidden;
  }

  dd {
    line-height: 1.42857143;
  }

  .itemDefList dt,
  .itemDefList dd {
    line-height: inherit;
  }

  dt {
    font-weight: 700;
  }
</style>
