<template>
  <div>
    <div class="row">
      <div class="col-12 nx-shipping-box">
        <div
          class="item_availability"
          data-toggle="modal"
          data-target="#nxShippingModal"
        >
          <template v-if="!isFree">
            <div class="icons info-svg">
              <svg
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                id="svg11"
                height="17"
                width="16"
                viewBox="0 0 16 17"
                version="1.2">
                <metadata
                  id="metadata15">
                  <rdf:RDF>
                    <cc:Work
                      rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type
                        rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    </cc:Work>
                  </rdf:RDF>
                </metadata>
                <title
                  id="title2">Versandkostenfrei</title>
                <defs
                  id="defs5">
                  <image
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAMAAADjcdz2AAAAAXNSR0IB2cksfwAAAORQTFRFAAAAg79Pg79PPkNHPkNHPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHPkNHg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79PPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHg79Pg79Pg79Pg79PPkNHPkNHg79Pg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79PPkNHPkNHTV5JRE5IPkNHPkNHPkNHPkNHPkNHsVzUoAAAAEx0Uk5TAHSGvtC0CXf/Rf/w4iphirVM/c0GsDv29CYDLO9iTy8e5Koa7DAV2OEQi7HaLQrJPJA1/vdnuIGVxQMP5/AgWVi7n9FQZli4YHBrCDdqbxMAAACUSURBVHicVcpVEsJAFETRxh+DJwR3CO7uOvj+90MCNZlw//pUA385nHC5RR4vfOQHY4FfwRDCFAFY1PrHFDVugCZ2IplKIyMhm6M8CkULSmWqoFrTBdQb1GyhzQzoqN0e+gNShsDIBG08mc7mRAsIwJKMVmsJmy3Rbg8JOBAdYQeczpBw4Zxfb/zb3YT/dDye9l7vD/ZyFgDjJaYsAAAAAElFTkSuQmCC"
                    id="img1"
                    height="17"
                    width="16" />
                </defs>
                <g
                  id="Versandkostenfrei">
                  <use
                    y="0"
                    x="0"
                    href="#img1"
                    id="☑ " />
                </g>
              </svg>
            </div>
            <div class="txt">
              <p class="green">
                Lieferung kostenfrei ab 49,90 &euro; netto (DE)
              </p>
              <p>{{ shippingText }}</p>
            </div>
            <div data-v-52709458="" class="icons">
              <i data-v-52709458="" class="fas fa-caret-down"></i>
            </div>
          </template>
          <template v-if="isFree">
            <div class="icons">
                            <svg
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                id="svg11"
                height="17"
                width="16"
                viewBox="0 0 16 17"
                version="1.2">
                <metadata
                  id="metadata15">
                  <rdf:RDF>
                    <cc:Work
                      rdf:about="">
                      <dc:format>image/svg+xml</dc:format>
                      <dc:type
                        rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                    </cc:Work>
                  </rdf:RDF>
                </metadata>
                <title
                  id="title2">Versandkostenfrei</title>
                <defs
                  id="defs5">
                  <image
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAMAAADjcdz2AAAAAXNSR0IB2cksfwAAAORQTFRFAAAAg79Pg79PPkNHPkNHPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHPkNHg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79PPkNHg79Pg79Pg79PPkNHPkNHg79Pg79Pg79PPkNHPkNHPkNHg79Pg79Pg79Pg79PPkNHPkNHg79Pg79Pg79Pg79Pg79PPkNHg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79Pg79PPkNHPkNHTV5JRE5IPkNHPkNHPkNHPkNHPkNHsVzUoAAAAEx0Uk5TAHSGvtC0CXf/Rf/w4iphirVM/c0GsDv29CYDLO9iTy8e5Koa7DAV2OEQi7HaLQrJPJA1/vdnuIGVxQMP5/AgWVi7n9FQZli4YHBrCDdqbxMAAACUSURBVHicVcpVEsJAFETRxh+DJwR3CO7uOvj+90MCNZlw//pUA385nHC5RR4vfOQHY4FfwRDCFAFY1PrHFDVugCZ2IplKIyMhm6M8CkULSmWqoFrTBdQb1GyhzQzoqN0e+gNShsDIBG08mc7mRAsIwJKMVmsJmy3Rbg8JOBAdYQeczpBw4Zxfb/zb3YT/dDye9l7vD/ZyFgDjJaYsAAAAAElFTkSuQmCC"
                    id="img1"
                    height="17"
                    width="16" />
                </defs>
   
                <g
                  id="Versandkostenfrei">
                  <use
                    y="0"
                    x="0"
                    href="#img1"
                    id="☑ " />
                </g>
              </svg>
            </div>
            <div class="txt">
              <p class="green">{{ shippingText }}</p>
            </div>
            <div data-v-52709458="" class="icons">
              <i data-v-52709458="" class="fas fa-caret-down"></i>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemDetailsShippingCostHint",
  props: {
    item: null,
  },
  data() {
    return {
      tooltip: "",
      tooltiptext: "",
      isFree: false,
      basketData: {},
    };
  },
  computed: {
    basketAmount() {
      let total = this.$store.state.basket.data.basketAmountNet;
      let shippingCost = this.$store.state.basket.data.shippingAmountNet;
      return shippingCost == 0 ? total : (total - shippingCost).toFixed(2);
    },
    basketAmountNET() {
      return this.$store.state.basket.data.itemSumNet ;
    },

    shippingText() {
      let price;
      let shippingLabel;
      let shippingCost = this.$store.state.basket.data.shippingAmountNet;
      let total = this.$store.state.basket.data.itemSumNet;
      this.isFree = shippingCost == 0 ;

      if (this.item) {
        price = this.item.prices.default.price.value;
      } else {
        price = this.$store.getters.variationGraduatedPrice.price.value;
      }
      /* 
      if(this.$store.getters.currentItemVariation.prices.graduatedPrices.length > 1){
        price = this.$store.getters.currentItemVariation.prices.graduatedPrices[1].data.basePriceNet 
        console.log(price)
        console.log( 'here is a bulck price' )
      }*/

      if (this.isFree && total > 0) {
        shippingLabel = "Lieferung kostenfrei (DE)";
        return shippingLabel;
      }
      if (price + this.basketAmountNET >= 49.9) {
        shippingLabel = "Lieferung kostenfrei (DE)";
        this.isFree = true;
        return shippingLabel;
      }
      if ( this.basketAmountNET == 0) {

        this.isFree = false;
      }
     
      

    /* shippingLabel =
        "Noch  " +
        Math.ceil((49.9 - this.basketAmountNET) / price) +
        " Stück oder andere Artikel für " +
        (49.9 - this.basketAmountNET).toFixed(2) +
        " € bis zur kostenfreien Lieferung";*/
     
      shippingLabel = 'Fügen Sie Artikel im Wert von '+ (49.9 - this.basketAmountNET).toFixed(2) +' € für die kostenfreie Lieferung hinzu.'

      return shippingLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
tspan { white-space:pre }
.nxtooltip {
  visibility: visible;
  position: relative;
  display: inline-block;
}

.nxtooltip a {
  text-decoration: none;
  color: #6c757d !important;
}

.nxtooltip .nxtooltiptext {
  visibility: hidden;
  text-align: center;
  border-radius: 0px;
  padding: 5px 0;
  width: 200px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0%;
  margin-left: -60px;
  background: black;
  color: white;
  white-space: normal;
  line-height: 1.5;

  &.large-tooltip {
    left: -50%;
  }
}

.shipping-cost-hint:hover .nxtooltiptext {
  visibility: visible;
}
.nxtooltip:hover .nxtooltiptext {
  visibility: visible;
}

.ww-small-hint {
  font-size: 12px;

  a {
    color: #fa7d19 !important;
  }
}

</style>
