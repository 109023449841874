var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-4",class:_vm.paddingClasses,style:(_vm.paddingInlineStyles)},[_c('article',{staticClass:"cmp cmp-product-thumb nx-item-list-item"},[_c('div',{staticClass:"container cross_sell"},[_c('div',{staticClass:"row flex cross_box nx-cs-images"},[_c('div',{staticClass:"col-6 img"},[_c('cross-selling-item-image',{attrs:{"image-urls-data":_vm._f("itemImages")(_vm.item.images,_vm.imageUrlAccessor),"alt-text":_vm._f("itemName")(_vm.item),"title-text":_vm._f("itemName")(_vm.item),"item-url":_vm._f("itemURL")(_vm.item,_vm.urlWithVariationId)}})],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"prices cross_price nx-cs-crossprice w-100"},[(
                    _vm.item.prices.rrp &&
                    _vm.item.prices.rrp.price.value > 0 &&
                    _vm.item.prices.rrp.price.value >
                      _vm.item.prices.default.unitPrice.value
                  )?[(_vm.item.prices.specialOffer)?_c('del',{staticClass:"crossprice"},[_vm._v("\n                    "+_vm._s(_vm.item.prices.default.unitPrice.value.toFixed(2))+" €\n                  ")]):_c('del',[_vm._v("\n                    "+_vm._s(_vm.item.prices.rrp.unitPrice.value.toFixed(2))+" €\n                  ")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"prices w-100"},[(_vm.item.item.itemType === 'set')?[_c('span',{staticClass:"unit-price nx-cs-unit-price"},[_vm._v(_vm._s(_vm.$translate("Ceres::Template.itemSetPrice", {
                        price: _vm.itemSetPrice,
                      }))+"€")])]:(
                    !!_vm.item.item &&
                    _vm.item.item.salableVariationCount > 1 &&
                    _vm.$ceres.isCheapestSorting
                  )?[_c('span',{staticClass:"unit-price nx-cs-unit-price"},[_vm._v(_vm._s(_vm.$translate("Ceres::Template.categoryItemFromPrice", {
                        price: _vm.itemPrice,
                      }))+"€")])]:[_c('span',{staticClass:"unit-price nx-cs-unit-price"},[_vm._v(_vm._s(_vm.item.prices.default.unitPrice.value.toFixed(2))+"€")])]],2),_vm._v(" "),_c('div',{staticClass:"w-100"},[_vm._t("text-muted",function(){return [_c('span',{staticClass:"vat small text-muted nx-vat-small"},[_c('shipping-cost-hint-small',{attrs:{"item":_vm.item}})],1)]})],2)])])]),_vm._v(" "),_c('div',{staticClass:"row nx-cs-name"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"nx-csitemname"},[_vm._v(_vm._s(_vm._f("itemName")(_vm.item)))])])]),_vm._v(" "),_c('div',{staticClass:"row nx-cs-buttons"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"graduated-prices"},[_c('div',{staticClass:"basket-preview-footer d-flex pb-3 cross_btns"},[_c('a',{staticClass:"btn btn-outline-primary btn-block btn-detail nx-cs-button",attrs:{"href":_vm._f("itemURL")(_vm.item,_vm.urlWithVariationId)}},[_vm._v("Details")]),_vm._v(" "),_c('span',{staticClass:"custom-add-to-basket-container nx-crosselling"},[_c('add-to-basket',{attrs:{"variation-id":_vm.item.variation.id,"is-salable":!!_vm.item.filter && _vm.item.filter.isSalable,"has-children":!!_vm.item.item && _vm.item.item.salableVariationCount > 1,"interval-quantity":_vm.item.variation.intervalOrderQuantity || 1,"minimum-quantity":_vm.item.variation.minimumOrderQuantity,"maximum-quantity":!!_vm.item.variation.maximumOrderQuantity &&
                      _vm.item.variation.maximumOrderQuantity > 0
                        ? _vm.item.variation.maximumOrderQuantity
                        : null,"order-properties":_vm.item.properties.filter(function (prop) {
                        return prop.property.isOderProperty;
                      }),"has-order-properties":_vm.item.hasOrderProperties,"use-large-scale":false,"show-quantity":false,"item-url":_vm._f("itemURL")(_vm.item,_vm.urlWithVariationId),"has-price":_vm._f("hasItemDefaultPrice")(_vm.item),"item-type":_vm.item.item.itemType}})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }