<template>
  <div class="block-posts">
    <p class="blog-list-title">Blog</p>

    <ul class="blog-list">
      <li
        v-for="(post, index) of blogPostsSortedByPublishedDate"
        v-if="index < 3"
      >
        <a :href="`https://www.net-xpress.de/blog/${post.post.urlName}`"
          ><p class="post-title">{{ post.post.title }}</p></a
        >
        <p class="post-excerpt">
          {{ post.post.shortDescription }}
          <a
            class="read-more"
            :href="`https://www.net-xpress.de/blog/${post.post.urlName}`"
            >weiterlesen...</a
          >
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "block-posts",

  props: {},

  async created() {
    const { data } = await axios.get("/rest/nx-theme/blogposts", {
      withCredentials: true,
    });
    this.blogPostsSortedByPublishedDate = data.entries
      .slice() // Make Copy of array
      .sort(
        (a, b) =>
          new Date(b.data.post.publishedAt) - new Date(a.data.post.publishedAt)
      ) // Sort newest published first
      .map((entry) => entry.data); // remove unnecessary metadata

    console.log(this.blogPostsSortedByPublishedDate);
  },

  data() {
    return {
      blogPostsSortedByPublishedDate: [],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.block-posts {
  .blog-list-title {
    margin-top: 20px;
    font-size: 36px;
    font-weight: 300;
    line-height: 48px;
    color: #555;
  }

  ul.blog-list {
    list-style: none;
    padding-left: 0;

    li {
      p.post-title {
        font: 700 16px/25px "Open sans condensed", sans-serif;
        color: #495057;
        margin-bottom: 0;
      }

      p.post-excerpt {
        font-size: 14px;
        line-height: 1.5;

        a.read-more {
          font-style: oblique;
          font-weight: 700;
          color: #495057;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
